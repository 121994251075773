import React from "react";
import Header from "../components/header";
import { graphql } from "gatsby";
import "../styles/styles.css";
import ReactHtmlParser from "react-html-parser";

const Contact = ({ data, pageContext }) => {
  return (
    <div>
      <Header back="true" taal={pageContext.langKey} />
      <div>
        <div className="textContent contactpage">
          {ReactHtmlParser(
            data.allMysqlPages.edges[0].node.content_nl.replace(
              'href="https://www.circusronaldo.be/nl/newsletter"',
              "target='_blank' href='https://m.circusronaldo.be/nieuwsbrief_nl.html'"
            )
          )}
          <p style={{ "text-align": "center" }}>
            <br />
            {/* <Link
              to="/newsletter"
              style={{ "font-weight": "bold", "font-size": "22px" }}>
              Schrijf je hier in voor onze nieuwsbrief
            </Link> */}
          </p>
        </div>
      </div>
    </div>
  );
};
export default Contact;
export const query = graphql`
  query {
    allMysqlPages(filter: { id: { regex: "/6/" } }) {
      edges {
        node {
          id
          label
          content_nl
        }
      }
    }
  }
`;
